import HelloWorld from '@/component/hello-world/index.vue'
import consumer_user_info from '@/lib/data-service/haolv-default/consumer_user_info'

export default {
    components: {
        HelloWorld,
    },
    data() {
        var checkAge = (rule, value, callback) => {
            this.ruleForm.childList.map(item => {
                if (!item.age) {
                    return callback(new Error('年龄不能为空'));
                } else {
                    callback();
                }
            })
        };
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                pass: '',
                checkPass: '',
                age: '',
                childList: [
                    {
                        age: ''
                    }
                ]
            },
            rules: {
                pass: [
                    {validator: validatePass, trigger: 'blur'}
                ],
                checkPass: [
                    {validator: validatePass2, trigger: 'blur'}
                ],

            }
        }
    },
    methods: {
        add() {
            this.ruleForm.childList.push({
                age: ''
            })
        },
        submitForm(formName) {
        },
        resetForm(formName) {
        }
    },
    created() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
        consumer_user_info().then(function (data) {
            console.log(data);
        });
    },
    deactivated() {
        // const __this = this;
    },
}
